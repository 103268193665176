.unleash-txt {
    color: #FFF;
    text-align: left;
    font-family: Orbitron;
    font-size: 58px;
    font-style: normal;
    font-weight: 700;
    line-height: 100.9%;
    letter-spacing: 2.56px;
}

.landing-desciption {
    margin-top: 9.5rem;
    margin-left: 3rem;
    max-width: 45rem;
    padding-left: 1rem;
    padding-right: 1rem;
}

.Unleash-word {
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    background: var(--linear, linear-gradient(90deg, #FF4300 0%, #FF6400 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.landing-desc-txt {
    margin-top: 3rem !important;
    color: #FFF;
    font-family: Outfit;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 170.9%;
    /* 30.762px */
    letter-spacing: 0.36px;
}

.free-trial-btn {
    color: #FFF !important;
    font-family: Outfit;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
    background-size: cover;
    background-position: center;
    text-transform: none !important;
    width: 15rem;
    height: 42px !important;
    margin-top: 3rem !important;
}

.fitfor-lifestyle-gradient {
    height: 187px;
    width: 100% !important;
    background: linear-gradient(180deg, rgba(24, 23, 26, 0.00) 0%, #18171A 100%);
}

.fitfor-heading {
    color: white;
    color: rgba(255, 255, 255, 0.90);
    font-family: Outfit;
    font-size: 48px !important;
    font-style: normal;
    font-weight: 600 !important;
    line-height: 120.9% !important;
    letter-spacing: 0.96px !important;
    text-align: center;
}

.fitfor-desc {
    color: #FFF;
    text-align: center !important;
    font-family: Outfit;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 170.9%;
    letter-spacing: 0.4px;
    max-width: 40rem;
    padding-top: 2rem;
    margin: auto !important;
}

.fitfor-image {
    margin-top: 3rem;
    width: 100%;
    max-width: 50rem;
    box-shadow: 0px 0px 5px #FF4300;
    border-radius: 7px;
}

.gym-package-title {
    background-color: #171717;
    transform: skewX(-18deg);
    transform-origin: top right;
    margin-left: -1rem;
    border: 1px solid #FF4300;

    color: #FFF;
    font-family: Outfit;
    font-size: 14px;
    font-style: normal !important;
    font-weight: 600;
    letter-spacing: 1.8px;
}

.subscribe-package-btn {
    color: #FFF !important;
    font-family: Outfit;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
    background-size: cover;
    background-position: center;
    text-transform: none !important;
    width: 15rem;
    height: 42px !important;
}

.package-box {
    cursor: pointer;
    transition: 0.4s !important;
}

.package-box:hover {
    cursor: pointer;
    scale: 1.065;
    transition: 0.4s;
}


.feedback-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 220px;
    background-color: #333;
    color: white;
    padding: 20px;
    text-align: center;
    border-radius: 5px;
}

.feedback-message {
    font-size: 18px;
    margin-bottom: 10px;
}

.client-name {
    font-size: 14px;
    font-style: italic;
}