.ab-brand-name {
    font-family: Outfit;
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;

    background: var(--linear, linear-gradient(90deg, #FF4300 0%, #FF6400 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.contact-submit-btn {
    color: #FFF !important;
    font-family: Outfit;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
    background-size: cover;
    background-position: center;
    text-transform: none !important;
    width: 12rem;
    height: 42px !important;
    margin-top: 1.5rem !important;
}