.footer-heading {
    color: #FF4300;
    font-family: Outfit;
    font-size: 42px !important;
    font-style: normal;
    font-weight: 600 !important;
    line-height: 120.9% !important;
    letter-spacing: 0.96px !important;
    text-align: center;
    padding-top: 3rem;
}

.footer-desc {
    color: rgba(255, 255, 255, 0.80);
    text-align: center;
    font-family: Outfit;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 166.5%;
    max-width: 60rem;
    margin: auto;
    margin-top: 2rem;
}